import { Password } from '@equitymultiple/react-eui';
import React, { useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path
} from 'react-hook-form';
import { setFieldProps } from 'utilities/formHelpers';
import { lowerCase, number, symbols, upperCase } from 'utilities/regex';
import utils from 'utilities/utils';

import * as styles from './SetPasswordFields.module.scss';

type PasswordStrength = 'low' | 'medium' | 'strong';

interface Props<T extends FieldValues> {
  changePassword?: boolean;
  control: Control<T>;
  errors: FieldErrors<T>;
  hidePasswordDescription?: boolean;
}

const SetPasswordFields = <T extends FieldValues>({
  changePassword,
  control,
  errors,
  hidePasswordDescription
}: Props<T>) => {
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [charLength, setCharLength] = useState(0);
  const [strength, setStrength] = useState<PasswordStrength>(null);

  const checkValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const passwordHasNumber = number.test(value);
    const passwordHasUpperCase = upperCase.test(value);
    const passwordHasLowerCase = lowerCase.test(value);
    const passwordHasSymbol = symbols.test(value);

    let strengthLevel = 0;

    if (value.length >= 8) strengthLevel += 2;
    if (passwordHasNumber) strengthLevel += 1;
    if (passwordHasUpperCase) strengthLevel += 1;
    if (passwordHasLowerCase) strengthLevel += 1;
    if (passwordHasSymbol) strengthLevel += 1;

    let passwordStrength: PasswordStrength = 'low';

    if (strengthLevel > 3) passwordStrength = 'medium';
    if (strengthLevel > 4) passwordStrength = 'strong';

    setHasNumber(passwordHasNumber);
    setHasUpperCase(passwordHasUpperCase);
    setHasLowerCase(passwordHasLowerCase);
    setHasSymbol(passwordHasSymbol);
    setCharLength(value.length);
    setStrength(passwordStrength);
  };

  const label = changePassword ? 'New Password' : 'Password';

  const renderHelperText = () => (
    <>
      <div>
        Password strength:{' '}
        <span className={`${styles.strengthText} ${styles[strength]}`}>
          {utils.capitalize(strength)}
        </span>
      </div>
      <div className={`${styles.strengthMeter} ${styles[strength]}`}>
        <div />
        {strength === 'medium' && <div />}
        {strength === 'strong' && (
          <>
            <div />
            <div />
          </>
        )}
      </div>

      {strength !== 'strong' && (
        <div className={styles.requirementsList}>
          <div
            className={`${styles.requirement} ${
              charLength >= 8 ? styles.met : ''
            }`}
          >
            8 Character Min.
          </div>
          <div
            className={`${styles.requirement} ${
              hasLowerCase ? styles.met : ''
            }`}
          >
            1 Lowercase
          </div>
          <div
            className={`${styles.requirement} ${
              hasUpperCase ? styles.met : ''
            }`}
          >
            1 Uppercase
          </div>
          <div
            className={`${styles.requirement} ${hasNumber ? styles.met : ''}`}
          >
            1 Number
          </div>
          <div
            className={`${styles.requirement} ${hasSymbol ? styles.met : ''}`}
          >
            1 Symbol
          </div>
        </div>
      )}
    </>
  );

  return (
    <div>
      {!hidePasswordDescription && (
        <div className="description margin-xx">
          Provide a secure password for your account. After this step, you'll be
          able to log in to EquityMultiple with your email and password anytime.
        </div>
      )}

      <Controller
        control={control}
        name={'password' as Path<T>}
        render={({ field }) => (
          <Password
            {...setFieldProps(field, errors)}
            className={`input-fixed-width-wide ${styles.passwordField}`}
            data-testid="password"
            helperText={charLength > 0 && renderHelperText()}
            helperTextAlwaysVisible
            label={label}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              checkValidation(e);
              field.onChange(e);
            }}
          />
        )}
      />

      <Controller
        control={control}
        name={'confirmPassword' as Path<T>}
        render={({ field }) => (
          <Password
            {...setFieldProps(field, errors)}
            className={`input-fixed-width-wide margin-top-xx ${styles.passwordField}`}
            data-testid="confirmPassword"
            label="Confirm Password"
          />
        )}
      />
    </div>
  );
};

export default SetPasswordFields;
