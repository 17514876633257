import { Button, Input, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import FormError from 'components/FormError/FormError';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Dispatch } from 'types/redux';
import { setFieldProps } from 'utilities/formHelpers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import RequireLoginWrap from '../components/RequireLoginWrap';
import {
  attributionAdvertisementOptions,
  attributionDetailSourceOptions,
  AttributionOption,
  attributionOptions,
  attributionResearchOptions
} from '../content';
import { discoverSchema } from '../validation';

interface FormFields {
  attribution?: AttributionOption;
  attribution_additional_influences?: string;
  attribution_advertisement_source?: string[];
  attribution_detail_source?: string;
  attribution_other_source?: string;
  attribution_research_source?: string[];
}

export interface Props {
  defaultValues: FormFields;
  dispatch: Dispatch;
}

const Discover = ({ defaultValues, dispatch }: Props) => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm<FormFields>({
    resolver: yupResolver(discoverSchema),
    values: defaultValues
  });

  const attribution = watch('attribution');
  const attributionResearchSource = watch('attribution_research_source');
  const attributionAdvertisementSource = watch(
    'attribution_advertisement_source'
  );

  useEffect(() => {
    document.title = 'Complete Signup | EquityMultiple';
  }, []);

  const onSubmit = values => {
    return dispatch(
      updateUserProfile({
        investor_profile_attributes: {
          ...values
        }
      })
    )
      .then(async () => {
        await dispatch(loadAuth());

        navigate('/users/signup/email_verify');
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const clearAttributionSubFields = () => {
    setValue('attribution_research_source', []);
    setValue('attribution_advertisement_source', []);
  };

  const showAdditionalInfluences =
    (attribution === 'Online Research' &&
      attributionResearchSource?.length > 0) ||
    (attribution === 'Advertisements' &&
      attributionAdvertisementSource?.length > 0) ||
    attribution === 'Referred by Fellow Investor';
  const showAttributionOtherSource =
    attributionResearchSource?.includes('Other');

  return (
    <RequireLoginWrap>
      <AccountProgressContainer showSkipLink signupStage="sign up">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="margin-x">How did you discover us?</h3>
          <p className="margin-xx">
            Your input here is deeply appreciated. Where you heard about
            EquityMultiple and what motivated you to sign up are essential
            insights for us to create better experiences for you and others.
          </p>

          <p>Where did you first learn about EquityMultiple?</p>
          <Controller
            control={control}
            name="attribution"
            render={({ field }) => (
              <Select
                {...setFieldProps(field, errors)}
                className="input-fixed-width-wide margin-xx"
                onChange={e => {
                  field.onChange(e);
                  clearAttributionSubFields();
                }}
                options={attributionOptions.map(option => ({
                  label: option,
                  value: option
                }))}
                placeholder="Select"
              />
            )}
          />

          {attribution === 'Online Research' && (
            <>
              <p>Where did you conduct research?</p>
              <Controller
                control={control}
                name="attribution_research_source"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    className="input-fixed-width-wide margin-xx"
                    multi
                    options={attributionResearchOptions}
                    placeholder="Type here"
                    showMultiLabelsBelow
                  />
                )}
              />

              {showAttributionOtherSource && (
                <>
                  <p>Where else did you conduct research?</p>
                  <Controller
                    control={control}
                    name="attribution_other_source"
                    render={({ field }) => (
                      <Input
                        {...setFieldProps(field, errors)}
                        className="input-fixed-width-wide margin-xx"
                        data-testid="attributionOtherSource"
                        placeholder="Type here"
                      />
                    )}
                  />
                </>
              )}
            </>
          )}

          {attribution === 'Advertisements' && (
            <>
              <p>Where did you spot an EquityMultiple advertisement?</p>
              <Controller
                control={control}
                name="attribution_advertisement_source"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    className="input-fixed-width-wide margin-xx"
                    multi
                    options={attributionAdvertisementOptions.map(option => ({
                      label: option,
                      value: option
                    }))}
                    placeholder="Select"
                    showMultiLabelsBelow
                  />
                )}
              />
            </>
          )}

          {attribution === 'Marcus and Millichap Relationship' && (
            <>
              <p>Which describes you?</p>
              <Controller
                control={control}
                name="attribution_detail_source"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    className="input-fixed-width-wide margin-xx"
                    options={attributionDetailSourceOptions.map(option => ({
                      label: option,
                      value: option
                    }))}
                    placeholder="Select"
                  />
                )}
              />
            </>
          )}

          {showAdditionalInfluences && (
            <>
              <p>
                Did anything else influence your decision to start an account
                with EquityMultiple? (optional)
              </p>
              <Controller
                control={control}
                name="attribution_additional_influences"
                render={({ field }) => (
                  <Input
                    {...setFieldProps(field, errors)}
                    className="input-fixed-width-wide margin-xx"
                    data-testid="additionalInfluences"
                    placeholder="Type here"
                  />
                )}
              />
            </>
          )}

          <FormError errors={errors} />

          <div className="forwardBackButtonWrapCompact">
            <Button
              className="button-fixed-width margin-top-xx"
              loading={isSubmitting}
              type="submit"
              variant="orange"
            >
              Continue
            </Button>
            <Button
              className="button-fixed-width margin-top-xx arrowBackButton"
              data-testid="backLink"
              disabled={isSubmitting}
              variant="outlined"
              wrapper={<Link to="/users/signup/phone" />}
            >
              <Back />
            </Button>
          </div>
        </form>
      </AccountProgressContainer>
    </RequireLoginWrap>
  );
};

function mapStateToProps(store) {
  let defaultValues = {};

  if (store.auth.user) {
    const investorProfileAttributes = store.auth.user.investor_profile;

    defaultValues = {
      attribution: investorProfileAttributes?.attribution,
      attribution_additional_influences:
        investorProfileAttributes?.attribution_additional_influences,
      attribution_advertisement_source:
        investorProfileAttributes?.attribution_advertisement_source || [],
      attribution_detail_source:
        investorProfileAttributes?.attribution_detail_source,
      attribution_research_source:
        investorProfileAttributes?.attribution_research_source || []
    };
  }

  return {
    defaultValues
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Discover);
