import {
  Button,
  DateSelect,
  FileUploader,
  Input,
  Select
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import { idTypes } from 'containers/Accounts/contents';
import { getIdTypeLabel } from 'containers/Accounts/helpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { loadAuth } from 'redux/actions/auth';
import {
  updatePersonalIdentification,
  updateUserProfile
} from 'redux/actions/user-settings';
import { UserIdDocument } from 'types/actions/account';
import { InvestorProfile, User } from 'types/actions/auth';
import { UpdatePersonalIdentificationPayload } from 'types/actions/user-settings';
import { Dispatch } from 'types/redux';
import { maxUploadSize } from 'utilities/constants';
import { setFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { personalIdSchema } from '../validation';

interface FormFields {
  id_document: File | UserIdDocument['name'];
  id_expiration: UserIdDocument['expiry'];
  id_type: UserIdDocument['type'];
  passport?: InvestorProfile['passport'];
}

interface Props {
  dispatch: Dispatch;
  user: User;
}

const PersonalIdentification = ({ dispatch, user }: Props) => {
  const [formVisible, setFormVisible] = useState(false);
  const [hasNewDoc, setHasNewDoc] = useState(false);

  const existingDocument = user.id_document?.name && user.id_document;

  let defaultValues = {};

  if (existingDocument) {
    defaultValues = {
      id_document: existingDocument.name,
      id_expiration: moment.utc(existingDocument.expiry).format('MM/DD/YYYY'),
      id_type: existingDocument.type,
      passport: user.investor_profile?.passport
    };
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm<FormFields>({
    defaultValues,
    resolver: yupResolver(personalIdSchema)
  });

  const idType = watch('id_type');

  useEffect(() => {
    if (!existingDocument) {
      setFormVisible(true);
    }
  }, [existingDocument]);

  const handleFileRemoval = () => {
    setValue('id_document', null);
    setHasNewDoc(true);
  };

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
  };

  const expirationDateFormatted =
    existingDocument?.expiry &&
    moment.utc(existingDocument.expiry).format('MM/DD/YYYY');
  const documentType = existingDocument && getIdTypeLabel(existingDocument);

  const onSubmit: SubmitHandler<FormFields> = values => {
    // Delete the id_document if it's the placeholder value used for validation
    if (typeof values.id_document === 'string') delete values.id_document;

    if (values.passport) {
      const submitValues = {
        investor_profile_attributes: {
          passport: values.passport
        }
      };
      dispatch(updateUserProfile(submitValues));
    }

    return dispatch(
      updatePersonalIdentification({
        user: values
      } as UpdatePersonalIdentificationPayload)
    )
      .then(res => {
        humane.notice(res.message);
        dispatch(loadAuth());
        setFormVisible(false);
        setHasNewDoc(false);
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={6}>
          <Controller
            control={control}
            name="id_document"
            render={({ field }) => (
              <FileUploader
                {...setFieldProps(field, errors)}
                acceptedFileTypes={['JPG', 'PNG']}
                existingFile={!hasNewDoc && existingDocument}
                hideRemove={!formVisible}
                maxSize={maxUploadSize}
                onRemove={handleFileRemoval}
                showImage={hasNewDoc}
                upload={(uploadData: File) => field.onChange(uploadData)}
                uploading={isSubmitting}
              />
            )}
          />
        </Col>
        <Col className="overflow-visible" sm={6}>
          {formVisible ? (
            <>
              <Controller
                control={control}
                name="id_type"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="Document Type"
                    options={idTypes}
                  />
                )}
              />
              <Controller
                control={control}
                name="id_expiration"
                render={({ field }) => (
                  <DateSelect
                    {...setFieldProps(field, errors)}
                    futureDate
                    label="ID Expiration Date"
                  />
                )}
              />
              {idType === 'passport' && (
                <Controller
                  control={control}
                  name="passport"
                  render={({ field }) => (
                    <Input
                      {...setFieldProps(field, errors)}
                      label="Passport Number"
                    />
                  )}
                />
              )}
            </>
          ) : (
            <>
              <div className="text-label">Document Type</div>
              <div className="text-dark margin-xx">{documentType}</div>
              <div className="text-label">Document Expiration Date</div>
              <div className="text-dark margin-x">
                {expirationDateFormatted}
              </div>
              <Button
                onClick={toggleFormVisibility}
                style={{ width: '140px' }}
                variant="outlined"
              >
                Edit
              </Button>
            </>
          )}
        </Col>
      </Row>
      {formVisible && (
        <>
          <FormError errors={errors} />
          <Button
            className="float-right"
            data-testid="profileIdSubmit"
            loading={isSubmitting}
            style={{ width: '140px' }}
            type="submit"
          >
            Update
          </Button>
        </>
      )}
    </form>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(PersonalIdentification);
