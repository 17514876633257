import {
  Button,
  EMLoadingIcon,
  Modal,
  RadioButton
} from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { cloneElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  clearLinkToken,
  loadLinkToken,
  loadLinkTokenUpdate,
  microDepositVerificationFailed,
  processLinkResults
} from 'redux/actions/bank-account';
import { BankAccount } from 'types/api/bankAccount';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { setRadioFieldProps } from 'utilities/formHelpers';
import utils from 'utilities/utils';

import { existingBankAccountSchema } from '../../validation';
import PlaidLinkCore from '../PlaidLinkCore';

const getErrorMessage = (errorCode, bankName = null) => {
  let errorMessage =
    'An error occured during bank account linking. Please try again.';
  const nameOfBank = bankName ? bankName : 'this bank';

  if (errorCode) {
    switch (errorCode) {
      case 'DUPLICATE_BANK_ACCOUNT':
        errorMessage =
          'You have already added this bank account. Please try again with a different account.';
        break;
      case 'DUPLICATE_CHASE':
        errorMessage =
          "Account linking succeeded. However, you've already linked an account with Chase. Because of this, your existing linked Chase account is now invalid. To add it back, please try again and select Chase.";
        break;
      case 'DUPLICATE_CHASE_IA':
        errorMessage =
          'Account linking succeeded. However, an account with Chase has already been linked using these credentials. Because of this, existing Chase accounts linked with these credentials are now invalid.';
        break;
      case 'DUPLICATE_INSTITUTION':
        errorMessage = `Account linking failed. It looks like you've already linked an account with ${nameOfBank}. In order to link another account with this bank, please try again and select this bank.`;
        break;
      case 'DUPLICATE_INSTITUTION_IA':
        errorMessage = `Account linking failed. It looks like an account with ${nameOfBank} has already been linked using these credentials.`;
        break;
      case 'DUPLICATE_SCHWAB':
        errorMessage =
          "Account linking succeeded. However, you've already linked an account with Charles Schwab. Because of this, your existing linked Charles Schwab account is now invalid. To add it back, please try again and select Charles Schwab.";
        break;
      case 'DUPLICATE_SCHWAB_IA':
        errorMessage =
          'Account linking succeeded. However, an account with Charles Schwab has already been linked using these credentials. Because of this, existing Charles Schwab accounts linked with these credentials are now invalid.';
        break;
    }
  }

  return errorMessage;
};

const clearLocalStorage = () => {
  utils.removeLocalStorage('oauthRedirectUri');
  utils.removeLocalStorage('plaidLinkRedirect');
  utils.removeLocalStorage('plaidLinkToken');
  utils.removeLocalStorage('plaidUpdateModeBankAccountId');
};

export const getPlaidLinkConfig = ({
  dispatch,
  investmentAccountId,
  investmentAccountReferencedId,
  investmentAccountType,
  isOAuth,
  linkToken,
  navigate,
  onError,
  onSuccess,
  tokenInLocalStorage
}) => ({
  onExit: (err, metadata) => {
    if (!metadata?.link_session_id)
      onError(
        'We were unable to initialize the linking process. Please try again.'
      );
    else if (err?.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
      const data = {
        bank_account_id: Number(
          utils.getLocalStorage('plaidUpdateModeBankAccountId')
        )
      };
      dispatch(microDepositVerificationFailed(investmentAccountId, data)).then(
        () => {
          onError(
            'You entered an incorrect amount too many times. You can attempt to add this bank account again, or try to add a different account.'
          );
        }
      );
      clearLocalStorage();
    }
    dispatch(clearLinkToken());
  },
  onSuccess: (_, metadata) => {
    const userSelectedMicroDepositVerification =
      metadata?.accounts[0]?.verification_status ===
      'pending_manual_verification';

    interface SubmitData {
      bank_account_id?: number;
      link_results: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    const data: SubmitData = {
      link_results: metadata
    };
    const bankName = metadata.institution?.name;
    const updateModeBankAccountId = utils.getLocalStorage(
      'plaidUpdateModeBankAccountId'
    );

    if (updateModeBankAccountId)
      data.bank_account_id = Number(updateModeBankAccountId);

    dispatch(processLinkResults(investmentAccountId, data))
      .then(async res => {
        if (onSuccess) onSuccess(res);

        EmAnalytics.track('Completes Linking New Bank Account', 'Onboarding', {
          account_type: utils.startCase(investmentAccountType),
          method: userSelectedMicroDepositVerification ? 'Microdeposit' : 'IAV'
        });

        clearLocalStorage();

        if (res.error_code) {
          const errorMessage = getErrorMessage(res.error_code, bankName);
          onError(errorMessage);
        } else if (
          res.investment_account_status === 'pending' &&
          res.status !== 'unverified'
        )
          navigate(
            `/accounts/wizard/${investmentAccountType}/thanks/${investmentAccountReferencedId}`
          );
      })
      .catch(err => {
        const errorCode = err.body.error_code;
        const errorMessage = getErrorMessage(errorCode, bankName);

        onError(errorMessage);
      });
  },
  token: isOAuth ? tokenInLocalStorage : linkToken
});

interface FormFields {
  bank: string;
}

interface Props {
  bankAccountId?: number;
  bankAccounts: BankAccount[];
  dispatch?: Dispatch;
  investmentAccountId?: number;
  investmentAccountReferencedId?: number;
  investmentAccountType?: string;
  isUpdate?: boolean;
  LaunchButton?: React.ReactElement;
  linkToken?: string;
  loading?: boolean;
  loadingToken?: boolean;
  onError?: (error: string) => void;
  onSuccess?: (res: { linkingError: string }) => void;
}

const PlaidLink = ({
  bankAccountId,
  bankAccounts,
  dispatch,
  investmentAccountId,
  investmentAccountReferencedId,
  investmentAccountType,
  isUpdate,
  LaunchButton,
  linkToken,
  loading,
  loadingToken,
  onError,
  onSuccess
}: Props) => {
  const navigate = useNavigate();

  const [existingAccountsModalOpen, setExistingAccountsModalOpen] =
    useState(false);
  const tokenInLocalStorage = utils.getLocalStorage('plaidLinkToken');
  const oauthRedirectUri = utils.getLocalStorage('oauthRedirectUri');
  const isOAuth = !!oauthRedirectUri;

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormFields>({
    resolver: yupResolver(existingBankAccountSchema)
  });

  useEffect(() => {
    utils.setLocalStorage('plaidLinkRedirect', window.location.pathname);
  }, []);

  useEffect(() => {
    utils.setLocalStorage('plaidUpdateModeBankAccountId', bankAccountId);
  }, [bankAccountId]);

  useEffect(() => {
    return () => {
      dispatch(clearLinkToken());
      clearLocalStorage();
    };
  }, [dispatch]);

  const plaidLinkConfig = {
    ...getPlaidLinkConfig({
      dispatch,
      investmentAccountId,
      investmentAccountReferencedId,
      investmentAccountType,
      isOAuth,
      linkToken,
      navigate,
      onError,
      onSuccess,
      tokenInLocalStorage
    }),
    receivedRedirectUri: isOAuth ? oauthRedirectUri : null
  };

  const { token } = plaidLinkConfig;

  const onReady = () => {
    setExistingAccountsModalOpen(false);
  };

  const updatableBankAccounts = bankAccounts?.filter(
    bankAccount => bankAccount.updatable
  );

  const handleLaunchButtonClick = () => {
    if (updatableBankAccounts?.length > 0 && !bankAccountId) {
      setExistingAccountsModalOpen(true);
    } else {
      dispatch(
        isUpdate
          ? loadLinkTokenUpdate(investmentAccountId, bankAccountId)
          : loadLinkToken(investmentAccountId)
      );
    }
  };

  const onSubmit = values => {
    const existingId = values.bank === 'differentBank' ? null : values.bank;
    utils.setLocalStorage('plaidUpdateModeBankAccountId', existingId);
    if (existingId) {
      dispatch(loadLinkTokenUpdate(investmentAccountId, Number(existingId)));
    } else {
      dispatch(loadLinkToken(investmentAccountId));
    }
  };

  return loading ? (
    <EMLoadingIcon data-testid="LoadingIcon" />
  ) : (
    <>
      <Modal
        onClose={() => setExistingAccountsModalOpen(false)}
        open={existingAccountsModalOpen}
        title={<h6>Select bank</h6>}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>Which bank does the bank account belong to?</p>

          <Controller
            control={control}
            name="bank"
            render={({ field }) => (
              <>
                {updatableBankAccounts?.map(bankAccount => (
                  <RadioButton
                    {...setRadioFieldProps(
                      field,
                      errors,
                      bankAccount.id.toString()
                    )}
                    hideError
                    id={`bankAccount${bankAccount.id}`}
                    key={bankAccount.id}
                    label={bankAccount.bank_name}
                  />
                ))}
                <RadioButton
                  {...setRadioFieldProps(field, errors, 'differentBank')}
                  label="A different bank"
                />
              </>
            )}
          />

          <FormError errors={errors} />

          <Button
            className="float-right"
            data-testid="submitButton"
            loading={loadingToken}
            type="submit"
          >
            Continue
          </Button>
        </form>
      </Modal>

      {token && <PlaidLinkCore onReady={onReady} options={plaidLinkConfig} />}

      {LaunchButton &&
        cloneElement(LaunchButton, {
          onClick: () => {
            LaunchButton.props.onClick();
            handleLaunchButtonClick();
          }
        })}
    </>
  );
};

function mapStateToProps(state) {
  return {
    bankAccounts: state.account.investmentAccount.bank_accounts,
    investmentAccountId: state.account.investmentAccount.id,
    investmentAccountReferencedId: state.account.investmentAccount.reference_id,
    investmentAccountType: state.account.investmentAccount.type,
    linkToken: state.bankAccount.linkToken,
    loading: state.bankAccount.loading,
    loadingToken: state.bankAccount.loadingToken
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(PlaidLink);
