import { yupResolver } from '@hookform/resolvers/yup';
import { formatDate, isComplete } from 'containers/Accounts/helpers';
import { InvestmentAccountFields } from 'containers/Accounts/types';
import { entitySchema } from 'containers/Accounts/validation';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getBusinessClassifications,
  loadInvestmentAccount,
  updateInvestmentAccount
} from 'redux/actions/account';
import {
  BusinessClassification,
  EntityInvestmentAccount,
  LoadInvestmentAccountAccount
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import AccountWrap from '../AccountWrap/AccountWrap';
import EntityAccountFields from './EntityAccountFields';

type Params = {
  reference_id: string;
};

interface Props {
  businessClassifications: BusinessClassification[];
  dispatch: Dispatch;
  investmentAccount: LoadInvestmentAccountAccount<EntityInvestmentAccount>;
  loading: boolean;
  residenceStatus: string;
}

const EntityAccountUpdate = ({
  businessClassifications,
  dispatch,
  investmentAccount,
  loading,
  residenceStatus
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const defaultValues = {
    investment_account: investmentAccount
      ? {
          address: investmentAccount.address,
          address2: investmentAccount.address2,
          business_class: investmentAccount.business_class,
          business_type: investmentAccount.business_type,
          city: investmentAccount.city,
          date_of_formation: formatDate(investmentAccount.date_of_formation),
          date_of_registration: formatDate(
            investmentAccount.date_of_registration
          ),
          ein: investmentAccount.ein,
          entity_current_bankruptcy:
            investmentAccount.entity_current_bankruptcy,
          entity_name: investmentAccount.entity_name,
          entity_portfolio_value: investmentAccount.entity_portfolio_value,
          entity_publicly_traded: investmentAccount.entity_publicly_traded,
          industry_class: investmentAccount.industry_class,
          jurisdiction_of_business_registration:
            investmentAccount.jurisdiction_of_business_registration,
          officer_title: investmentAccount.officer_title,
          postal_code: investmentAccount.postal_code,
          region: investmentAccount.region,
          retirement_purpose_vehicle:
            investmentAccount.retirement_purpose_vehicle
        }
      : {}
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    watch
  } = useForm<InvestmentAccountFields>({
    resolver: yupResolver(entitySchema),
    values: defaultValues
  });

  const businessType = watch('investment_account.business_type');
  const einVerificationOptional = [
    'soleproprietorship',
    'trust_revocable'
  ].includes(businessType);
  let businessClass = watch('investment_account.business_class');
  if (businessClass) businessClass = businessClass.split(':')[0];

  useEffect(() => {
    document.title = 'My Accounts | Entity Account';
  }, []);

  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id)).catch(error =>
      handleErrorResponse(navigate, error)
    );
    dispatch(getBusinessClassifications()).catch(error =>
      handleErrorResponse(navigate, error)
    );
  }, [dispatch, navigate, params]);

  const onSubmit = (values: InvestmentAccountFields) => {
    values.investment_account.id = investmentAccount.id;
    return dispatch(updateInvestmentAccount(values))
      .then(() => {
        if (isComplete(investmentAccount)) {
          navigate(`/accounts/update/entity/${investmentAccount.reference_id}`);
        } else {
          navigate(
            `/accounts/wizard/entity/organizational_document/${investmentAccount.reference_id}`
          );
        }
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const isLoading = loading || !businessClassifications?.length;

  return (
    <AccountWrap account={investmentAccount} loading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EntityAccountFields
          businessClass={businessClass}
          businessClassifications={businessClassifications}
          control={control}
          einVerificationOptional={einVerificationOptional}
          errors={errors}
          investmentAccount={investmentAccount}
          loading={isLoading}
          residenceStatus={residenceStatus}
          submitting={isSubmitting}
        />
      </form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    businessClassifications: state.account.businessClassifications,
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading,
    residenceStatus: state.auth.user?.investor_profile?.residence_status
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(EntityAccountUpdate);
