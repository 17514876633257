import { Button, Modal, Table, Tooltip } from '@equitymultiple/react-eui';
import { getSingleOwnerAccountLabels } from 'containers/VerifyAccreditation/helpers';
import checkGreen from 'images/icons/check-circle-green.svg?url';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadInvestmentAccounts } from 'redux/actions/account';
import { LoadInvestmentAccountsResponse } from 'types/actions/account';
import { Dispatch } from 'types/redux';
import { adBlockerIntercomTooltip } from 'utilities/constants';
import utils from 'utilities/utils';

import * as sharedStyles from '../../SettingsShared.module.scss';
import * as styles from './Accreditation.module.scss';

const singleOwnerEntityTypes = ['soleproprietorship', 'single_member_llc'];
const accreditationStatusesToDisplay = [
  'verified',
  'not_verified',
  'pending',
  'expired'
];
const actionableAccreditationStatuses = [
  'not_started',
  'not_verified',
  'incomplete',
  'expired'
];

const getListableAccounts = (
  accounts: LoadInvestmentAccountsResponse
): LoadInvestmentAccountsResponse => {
  return accounts.filter(account => {
    const isIndividual = account.type === 'individual';
    const isJoint = account.type === 'joint account';
    const isListableEntity =
      account.type === 'entity' &&
      !singleOwnerEntityTypes.includes(account.business_type);
    const isComplete = account.status === 'created';
    return isIndividual || (isComplete && (isJoint || isListableEntity));
  });
};

const getButton = (status: string, accountReferenceId: number): JSX.Element => {
  let buttonText = 'Verify Now';
  switch (status) {
    case 'incomplete':
      buttonText = 'Complete Accreditation';
      break;
    case 'not_verified':
      buttonText = 'Retry Verification';
  }

  return (
    <Button
      className={styles.verifyButton}
      data-testid="verifyButton"
      variant="outlined"
      wrapper={<Link to={`/verify_accreditation/${accountReferenceId}`} />}
    >
      {buttonText}
    </Button>
  );
};

const getDisplayedAccreditationStatus = (
  status: string,
  verifyInvestorStatus: string,
  expiredAt: string
): JSX.Element => {
  if (status === 'verified') {
    return (
      <span className={styles.verified}>
        <img alt="Verified" src={checkGreen} />
        Verified
      </span>
    );
  } else {
    let tooltipContent: React.ReactChild | string = '';

    switch (status) {
      case 'not_verified':
        tooltipContent = (
          <span data-testid="tooltipText">
            We could not verify you as an accredited investor at this time. If
            you believe this to be an error please contact us with the chat icon{' '}
            <Tooltip
              infoIcon
              infoIconColor="#fff"
              tooltipContent={adBlockerIntercomTooltip}
            />{' '}
            on the bottom right of your screen or email us at{' '}
            <a href="mailto:ir@equitymultiple.com">ir@equitymultiple.com</a>
          </span>
        );
        if (verifyInvestorStatus === 'self_not_accredited')
          tooltipContent = (
            <span data-testid="tooltipText">
              You’ve indicated that you are not an accredited investor through
              VerifyInvestor. If you believe this to be an error please contact
              us with the chat icon{' '}
              <Tooltip infoIcon tooltipContent={adBlockerIntercomTooltip} /> on
              the bottom right of your screen or email us at{' '}
              <a href="mailto:ir@equitymultiple.com">ir@equitymultiple.com</a>
            </span>
          );

        break;
      case 'pending':
        tooltipContent =
          "We have received your verification documents, and they are in our reviewer's queue. You will be notified when a determination is made. In the meantime, you may continue with your investment process.";
        if (verifyInvestorStatus === 'in_review')
          tooltipContent =
            'Your verification documents are in review. You will be notified when a determination is made. In the meantime, you may continue with your investment process.';
        else if (
          verifyInvestorStatus === 'waiting_for_information_from_investor'
        )
          tooltipContent = (
            <span data-testid="tooltipText">
              The reviewer has requested additional information. Please check
              your email for details. If you have any questions please contact
              us with the chat icon{' '}
              <Tooltip
                infoIcon
                infoIconColor="#fff"
                tooltipContent={adBlockerIntercomTooltip}
              />{' '}
              on the bottom right of your screen or email us at{' '}
              <a href="mailto:ir@equitymultiple.com">ir@equitymultiple.com</a>
            </span>
          );
        break;
      case 'expired':
        tooltipContent = `Your accreditation expired on ${utils.dateFormat(
          expiredAt
        )}. To continue investing with this investment account, you must verify your account.`;
    }

    return (
      <div className={styles.statusWrap}>
        <span className={status}>{utils.snakeCaseToTitleCase(status)}</span>
        <Tooltip
          className="info-icon-margin-left"
          infoIcon
          tooltipContent={tooltipContent}
        />
      </div>
    );
  }
};

const getColumnHeaders = (
  accounts: LoadInvestmentAccountsResponse,
  loading: boolean
): string[] => {
  const columnHeaders = ['Account Accreditation(s)'];

  const accountsToList = accounts && getListableAccounts(accounts);

  if (!loading && accountsToList) {
    if (
      accountsToList.some(account =>
        accreditationStatusesToDisplay.includes(account.accreditation_status)
      )
    )
      columnHeaders.push('Accredited Status');
    if (
      accountsToList.some(account =>
        actionableAccreditationStatuses.includes(account.accreditation_status)
      )
    )
      columnHeaders.push('');
  }

  return columnHeaders;
};

const getRows = (
  accounts: LoadInvestmentAccountsResponse,
  loading: boolean
): { cells: JSX.Element[] }[] => {
  if (!loading && accounts) {
    const individualAccountId = accounts.find(
      account => account.type === 'individual'
    )?.id;

    const accountsToList = getListableAccounts(accounts);

    const someAccountHasDisplayedAccreditationStatus = accountsToList.some(
      account =>
        accreditationStatusesToDisplay.includes(account.accreditation_status)
    );
    const someAccountHasActionableStatus = accountsToList.some(account =>
      actionableAccreditationStatuses.includes(account.accreditation_status)
    );

    return accountsToList.map(account => {
      const status = account.accreditation_status;
      let accountDescription = utils.titalize(account.type);
      if (account.id === individualAccountId)
        accountDescription = getSingleOwnerAccountLabels(accounts);
      const row = {
        cells: [
          <div className={styles.accountDetails} key="accountDetails">
            {account.entity_name}
            <div className={styles.accountDescription}>
              {accountDescription}
            </div>
          </div>
        ]
      };

      if (accreditationStatusesToDisplay.includes(status))
        row.cells.push(
          getDisplayedAccreditationStatus(
            status,
            account.verifyinvestor_accreditation_status,
            account.accreditation_expires_at
          )
        );
      else if (someAccountHasDisplayedAccreditationStatus) {
        row.cells.push(<span key="empty-cell" />);
      }

      if (actionableAccreditationStatuses.includes(status))
        row.cells.push(getButton(status, account.reference_id));
      else if (someAccountHasActionableStatus) {
        row.cells.push(<span key="empty-cell" />);
      }

      return row;
    });
  }
  return [];
};

interface AccreditationProps {
  dispatch: Dispatch;
  investmentAccounts: LoadInvestmentAccountsResponse;
  loading: boolean;
  successModalAccountName: string | string[];
}

const Accreditation: React.FC<AccreditationProps> = ({
  dispatch,
  investmentAccounts,
  loading,
  successModalAccountName
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    dispatch(loadInvestmentAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (successModalAccountName) setModalVisible(true);
  }, [successModalAccountName]);

  return (
    <div className="margin-top-xxx" data-testid="accreditationTab">
      <Modal
        className={styles.accreditationSuccessModal}
        data-testid="accreditationSuccessModal"
        onClose={() => setModalVisible(false)}
        open={modalVisible}
      >
        <h3 className="margin-top-0 margin-xx">
          You've completed the accreditation process
        </h3>
        <p>
          Thank you for completing the accreditation process for "
          {successModalAccountName}". Our team will review the accreditation
          evidence provided within 1-2 business days. You can track the status
          of your accreditation here, on the settings page.
        </p>
      </Modal>
      <Row className="margin-xxxx">
        <Col lg={3}>
          <h3 className={sharedStyles.h3}>My Accreditation</h3>
        </Col>
        <Col lg={9}>
          <p className="margin-xxx">
            The SEC requires that all investors are accredited (see definition
            of Accredited per the SEC{' '}
            <a
              className="underline"
              data-testid="secDefinitionLink"
              href="https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR6e651a4c86c0174/section-230.501"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            ) and that EquityMultiple performs adequate steps to verify
            accreditation. We may use third-party data sources to automatically
            verify your accredited status where possible. Learn more about
            accreditation on our{' '}
            <a
              className="underline"
              href="https://www.equitymultiple.com/faq"
              rel="noopener noreferrer"
              target="_blank"
            >
              FAQ
            </a>{' '}
            page.
          </p>
          <Table
            className={styles.accountsTable}
            columnHeaders={getColumnHeaders(investmentAccounts, loading)}
            loading={loading}
            loadingRows={1}
            rows={getRows(investmentAccounts, loading)}
          />
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    investmentAccounts: store.account.investmentAccounts,
    loading: store.account.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(Accreditation);
