import { yupResolver } from '@hookform/resolvers/yup';
import { InvestmentAccountFields } from 'containers/Accounts/types';
import { entitySchema } from 'containers/Accounts/validation';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createInvestmentAccount,
  getBusinessClassifications
} from 'redux/actions/account';
import {
  BusinessClassification,
  CreateInvestmentAccountPayload
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import AccountWrap from '../AccountWrap/AccountWrap';
import EntityAccountFields from './EntityAccountFields';

interface Props {
  businessClassifications: BusinessClassification[];
  dispatch: Dispatch;
  loading: boolean;
  residenceStatus: string;
}

const NewEntityAccount = ({
  businessClassifications,
  dispatch,
  loading,
  residenceStatus
}: Props) => {
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    watch
  } = useForm<InvestmentAccountFields>({
    resolver: yupResolver(entitySchema)
  });

  const businessType = watch('investment_account.business_type');
  const einVerificationOptional = [
    'soleproprietorship',
    'trust_revocable'
  ].includes(businessType);
  let businessClass = watch('investment_account.business_class');
  if (businessClass) businessClass = businessClass.split(':')[0];

  useEffect(() => {
    document.title = 'My Accounts | Entity Account';
  }, []);

  useEffect(() => {
    dispatch(getBusinessClassifications()).catch(error =>
      handleErrorResponse(navigate, error)
    );
  }, [dispatch, navigate]);

  const onSubmit = (values: InvestmentAccountFields) => {
    values.investment_account.type = 'entity';
    return dispatch(
      createInvestmentAccount(values as CreateInvestmentAccountPayload)
    )
      .then(res => {
        const referenceId = res.investment_account.reference_id;
        EmAnalytics.track('Submits Entity Account Information', 'Onboarding');
        navigate(
          `/accounts/wizard/entity/organizational_document/${referenceId}`
        );
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const isLoading = loading || !businessClassifications?.length;

  return (
    <AccountWrap loading={isLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EntityAccountFields
          backLinkRoute="/accounts/new"
          businessClass={businessClass}
          businessClassifications={businessClassifications}
          control={control}
          einVerificationOptional={einVerificationOptional}
          errors={errors}
          loading={isLoading}
          residenceStatus={residenceStatus}
          submitting={isSubmitting}
        />
      </form>
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  const props = {
    businessClassifications: state.account?.businessClassifications,
    loading: state.account.loading,
    residenceStatus: state.auth.user?.investor_profile?.residence_status
  };

  return props;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(NewEntityAccount);
