import { Button, Card } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import SetPasswordFields from 'components/forms/SetPasswordFields/SetPasswordFields';
import { setPasswordSchema } from 'components/forms/SetPasswordFields/validation';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import { HeaderSimple } from '../../components/HeaderSimple';
import {
  resetPassword,
  validateResetPasswordToken
} from '../../redux/actions/auth';
import { Dispatch } from '../../types/redux';
import humane from '../../utilities/humane';

interface FormFields {
  confirmPassword: string;
  password: string;
}

type Props = {
  dispatch: Dispatch;
};

const ResetPassword = ({ dispatch }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(setPasswordSchema)
  });

  const redirectOnError = () => {
    humane.error(
      'Reset password token is invalid. Please resend reset password instructions.'
    );
    navigate('/users/sign_in', { replace: true });
  };

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query?.reset_password_token) {
      dispatch(validateResetPasswordToken(query.reset_password_token)).catch(
        () => {
          redirectOnError();
        }
      );
    } else {
      redirectOnError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = formData => {
    const query = queryString.parse(location.search);

    if (query) {
      const data = {
        user: {
          password: formData.password,
          reset_password_token: query.reset_password_token as string
        }
      };

      return dispatch(resetPassword(data))
        .then(() => {
          humane.notice(
            'Password has been reset sucessfully, you may log in with your new password.'
          );
          navigate('/users/sign_in', { replace: true });
        })
        .catch(res => throwReactHookFormSubmissionErrors(res, setError));
    }
    return null;
  };

  return (
    <div>
      <HeaderSimple />
      <Container>
        <Card>
          <h4 className="margin-top-0">Choose a New Password</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SetPasswordFields<FormFields> control={control} errors={errors} />
            <FormError errors={errors} />
            <Button
              className="button-fixed-width float-right margin-top-xx"
              loading={isSubmitting}
              type="submit"
            >
              Continue
            </Button>
          </form>
        </Card>
      </Container>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(ResetPassword);
