import { Button, Card, Input, RadioButton } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import { supportsBeneficialOwners } from 'containers/Accounts/helpers';
import useRedirectUserWIthInterestAscentInvestment from 'hooks/useRedirectUserWIthInterestAscentInvestment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadCompletedAccounts } from 'redux/actions/account';
import { LoadCompletedAccountsResponse } from 'types/actions/account';
import { LoadInvestmentResponse } from 'types/actions/investments';
import { LoadClosingResponse } from 'types/actions/offerings';
import { setFieldProps, setRadioFieldProps } from 'utilities/formHelpers';

import Contact from '../../../../components/Contact/Contact';
import ProgressBarBorder from '../../../../components/ProgressBarBorder/ProgressBarBorder';
import {
  clearInvestment,
  loadInvestment,
  sendPledge,
  updateInvestment
} from '../../../../redux/actions/investments';
import { loadClosing } from '../../../../redux/actions/offerings';
import { User } from '../../../../types/actions/auth';
import { Dispatch } from '../../../../types/redux';
import EmAnalytics from '../../../../utilities/em_analytics';
import { handleErrorResponse } from '../../../../utilities/errorHandlers';
import { numberMaskOptions } from '../../../../utilities/masks';
import utils from '../../../../utilities/utils';
import { throwReactHookFormSubmissionErrors } from '../../../../utilities/validation';
import InterestOrWaitlistSummary from '../../components/InterestOrWaitlistSummary';
import InvestmentStatus from '../../components/InvestmentStatus/InvestmentStatus';
import InvestmentTitle from '../../components/InvestmentTitle/InvestmentTitle';
import {
  checkIfEditing,
  redirectIfInvestmentIsClosed,
  redirectIfPendingRollover,
  redirectIfUserCannotInvest
} from '../../helpers';
import { interestSchema } from '../../validations';
import * as styles from './../../Investment.module.scss';

const LoadingSkeleton = () => (
  <Card className="border-top-card">
    <div data-testid="loadingSkeleton">
      <h5>
        <Skeleton width="30%" />
      </h5>
      <p>
        <Skeleton width="70%" />
      </p>
      <h6>
        <Skeleton width="30%" />
      </h6>
      <p className="margin-xxx">
        <Skeleton width="50%" />
      </p>
      <h6>
        <Skeleton width="40%" />
      </h6>
      <p>
        <Skeleton width="90%" />
      </p>
      <p>
        <Skeleton width="30%" />
      </p>
    </div>
  </Card>
);

type Params = {
  closing_id: string;
  investment_id: string;
};

interface FormFields {
  allowPartialShares?: boolean;
  id?: number;
  increment?: number;
  investment_account?: string;
  investment_amount?: string;
  range?: {
    maxRange?: number;
    minRange?: number;
  };
}

interface Props {
  accounts: LoadCompletedAccountsResponse;
  closing: LoadClosingResponse['closing'];
  defaultValues: FormFields;
  dispatch: Dispatch;
  editingInvestment: boolean;
  investment: LoadInvestmentResponse;
  loading: boolean;
  offering: LoadClosingResponse['offering'];
  user: User;
}

const IndicateInterest = ({
  accounts,
  closing,
  defaultValues,
  dispatch,
  editingInvestment,
  investment,
  loading,
  offering,
  user
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const location = useLocation();
  useRedirectUserWIthInterestAscentInvestment(dispatch, offering);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(interestSchema),
    values: defaultValues
  });

  const [editRoute, setEditRoute] = useState(false);

  useEffect(() => {
    redirectIfUserCannotInvest(navigate, user);

    dispatch(clearInvestment());

    document.title = 'Indicate Interest | EquityMultiple';

    if (
      location.pathname === '/invest/:closing_id/investment/:investment_id/edit'
    )
      setEditRoute(true);
    dispatch(loadClosing(params.closing_id))
      .then(res => {
        redirectIfInvestmentIsClosed(navigate, res.closing);
        if (res.investment?.id) {
          dispatch(loadInvestment(res.investment.id))
            .then(investmentResponse => {
              redirectIfPendingRollover(
                navigate,
                investmentResponse,
                params.closing_id
              );
            })
            .catch(error => {
              handleErrorResponse(
                navigate,
                error,
                `/invest/${params.closing_id}/investment/new`
              );
            });
        }
      })
      .catch(error => handleErrorResponse(navigate, error));
    dispatch(loadCompletedAccounts()).catch(error =>
      handleErrorResponse(navigate, error)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = values => {
    if (values.id) {
      const data = {
        closing_id: closing.id,
        investment: {
          amount: utils.currencyStringToNumber(values.investment_amount),
          investment_account_id: values.investment_account
        }
      };

      return dispatch(updateInvestment(data, values.id))
        .then(res => {
          if (res.status === 'waitlist')
            navigate(`/invest/${closing.id}/investment/${res.id}/interest`);
          else navigate(`/invest/${closing.id}/investment/${res.id}/sign`);
        })
        .catch(error => throwReactHookFormSubmissionErrors(error, setError));
    } else {
      const data = {
        closing_id: closing.id,
        investment: {
          amount: values.investment_amount,
          investment_account_id: values.investment_account
        },
        offering_id: offering.id
      };
      return dispatch(sendPledge(data))
        .then(res => {
          if (res.status === 'interest') {
            EmAnalytics.track('Investment Started', 'Investment', {
              amount: res.amount,
              close_on: res.close_on,
              currency: 'USD',
              first_time_investment: res.first_time_investment,
              investment_account_type: res.investment_account_type,
              investmentId: res.id,
              label: 'Amount',
              offering_title: res.offering_title,
              offering_type: res.offering_type,
              orderId: `${res.user_id}.${res.id}`,
              value: res.amount
            });
          }
          if (res.status !== 'waitlist') {
            navigate(`/invest/${closing.id}/investment/${res.id}/sign`);
          } else {
            navigate(`/invest/${closing.id}/investment/${res.id}/interest`);
          }
        })
        .catch(error => throwReactHookFormSubmissionErrors(error, setError));
    }
  };

  const stepDisabled = investment?.id && investment?.status === 'waitlist';
  const notEdit = !(editRoute || editingInvestment);

  const closingInInterestOrWaitlist =
    closing?.stage === 'interest' || closing?.stage === 'waitlist';
  const investmentOnWaitlist = investment?.status === 'waitlist';
  const showInterestOrWaitlistSummary =
    closingInInterestOrWaitlist && investmentOnWaitlist;

  // TODO: Remove the investment class and convert this to modules once all child components have been refactored to use modules
  return (
    <div className="investment">
      <Container className="container-narrow">
        <InvestmentTitle
          loading={loading}
          stage={closing.stage}
          title={offering.title}
        />
        <InvestmentStatus
          closing={closing}
          editingInterestPage={!notEdit}
          investment={investment}
          loading={loading}
          step={1}
        />
        {loading ? (
          <LoadingSkeleton />
        ) : showInterestOrWaitlistSummary ? (
          <InterestOrWaitlistSummary
            closingStage={closing?.stage}
            investmentAmount={investment?.amount}
          />
        ) : (
          <Card className="border-top-card" data-testid="indicateInterestForm">
            <ProgressBarBorder currentStep={1} steps={4} />
            <h3 className="margin-top-0 margin-x">
              {notEdit ? 'Indicate Interest' : 'Select New Investment Amount'}
            </h3>
            <p>
              A Pledge is an indication of interest, and does not reserve your
              position in the offering.
            </p>
            <h4>How much are you investing?</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <Controller
                    control={control}
                    name="investment_amount"
                    render={({ field }) => (
                      <Input
                        {...setFieldProps(field, errors)}
                        className="dollar-amount"
                        dollarMask
                        inputMaskOptions={numberMaskOptions}
                        label="Investment Amount"
                        maxLength={12}
                      />
                    )}
                  />
                </Col>
              </Row>
              {notEdit && (
                <div>
                  <div className="margin-xx">
                    <h4>Select how you&apos;ll be investing</h4>
                    <p>
                      Select the Account to use for your investment. Note that
                      under SEC rules, we are required to verify the current
                      accreditation status of the account.
                    </p>
                    <Controller
                      control={control}
                      name="investment_account"
                      render={({ field }) => (
                        <>
                          {accounts.map((account, index) => {
                            const pending = Boolean(
                              account.type === 'entity' &&
                                supportsBeneficialOwners(account) &&
                                account.needs_update
                            );

                            const accountUpdateLink = (
                              <Link
                                to={`/accounts/update/${account.type}/rep/new/${account.plain_reference_id}`}
                              >
                                [Confirm/Add Beneficial Owners]
                              </Link>
                            );
                            const label = (
                              <span>
                                {account.entity_name} (
                                {account.type === 'ira'
                                  ? 'IRA'
                                  : account.type.charAt(0).toUpperCase() +
                                    account.type.slice(1)}
                                ) {pending ? accountUpdateLink : ''}
                              </span>
                            );

                            const accountId = account.id.toString();

                            return (
                              <RadioButton
                                {...setRadioFieldProps(
                                  field,
                                  errors,
                                  accountId
                                )}
                                disabled={pending}
                                hideError={index !== accounts.length - 1}
                                key={account.id}
                                label={label}
                              />
                            );
                          })}
                        </>
                      )}
                    />
                  </div>
                </div>
              )}

              <FormError errors={errors} />

              <div
                className={`forwardBackButtonWrapCompact float-right ${styles.formButtons}`}
              >
                <Button
                  className={styles.submitButton}
                  disabled={stepDisabled}
                  loading={isSubmitting}
                  type="submit"
                  variant="orange"
                >
                  Next
                </Button>
              </div>
            </form>
          </Card>
        )}

        <Contact />
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  const investment = state.investments.investment;
  let defaultValues: FormFields = {};
  let isEditing = false;
  let totalFunded = 0;
  let amount = 0;
  if (
    state.offerings.offering &&
    state.offerings.closing &&
    state.account.completedAccounts
  ) {
    const { completedAccounts } = state.account;
    const minimumInvestmentAmount =
      state.offerings.closing.min_investment_amount;
    const maximumInvestmentAmount =
      state.offerings.closing.max_investment_amount;
    const {
      investment_increment: investmentIncrement,
      partial_shares: partialShares
    } = state.offerings.offering;
    let minRange = minimumInvestmentAmount;
    if (investment?.override_minimum)
      minRange = investment.custom_minimum_investment_amount;
    if (investment) amount = investment.amount;
    if (investment?.transactions?.length > 0) {
      isEditing = checkIfEditing(
        investment.amount,
        investment.transactions.contributions,
        investment.transactions.refunds
      );
      if (isEditing) {
        investment.transactions.contributions.forEach(transaction => {
          totalFunded += parseFloat(transaction.amount);
        });
        if (investment.transactions.refunds) {
          investment.transactions.refunds.forEach(transaction => {
            totalFunded -= parseFloat(transaction.amount);
          });
        }
        amount = totalFunded;
      }
    }

    if (isEditing || window.location.href.indexOf('/edit') > -1)
      minRange =
        // This can be both a string or number. Calling parseFloat on a number is harmless
        parseFloat(amount as unknown as string) +
        parseFloat(investmentIncrement);

    defaultValues = {
      allowPartialShares: partialShares,
      increment: investmentIncrement,
      investment_account:
        completedAccounts.length === 1
          ? `${completedAccounts[0].id}`
          : undefined,
      range: {
        maxRange: parseFloat(maximumInvestmentAmount),
        minRange
      }
    };

    if (investment?.id) {
      defaultValues.id = investment.id;
      defaultValues.investment_amount = utils.formatCurrency(
        investment.amount,
        0
      );
      defaultValues.investment_account = `${investment.investment_account_id}`;
    }
  }

  return {
    accounts: state.account.completedAccounts,
    closing: state.offerings.closing,
    defaultValues,
    editingInvestment: isEditing,
    investment: state.investments.investment || state.offerings.investment,
    loading:
      state.offerings.loadingClosing ||
      state.investments.loading ||
      state.account.loading,
    offering: state.offerings.offering,
    user: state.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(IndicateInterest);
