import { Button, Checkbox, Select } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  allResidencyStatuses,
  countryOptions
} from 'containers/Accounts/contents';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { InvestorProfile } from 'types/actions/auth';
import { UpdateAlert } from 'types/actions/user-update-alerts';
import { setCheckboxFieldProps, setFieldProps } from 'utilities/formHelpers';

import { SubmittedUpdateAlert } from '../types';
import { residenceSchema } from '../validation';

type FormFields = Pick<
  InvestorProfile,
  | ('country_of_citizenship' & {
      accepted_residency_requirements: boolean;
      accepted_tos: boolean;
    })
  | 'residence_status'
>;

interface Props {
  alert: UpdateAlert;
  handleSubmitAlert: (alert: SubmittedUpdateAlert) => void;
}

const Residence = ({ alert, handleSubmitAlert }: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm<FormFields>({
    resolver: yupResolver(residenceSchema)
  });

  const onSubmit = values => {
    if (values.residence_status === 'U.S. Citizen')
      values.country_of_citizenship = 'United States';
    handleSubmitAlert({
      ...alert,
      action: values.residence_status,
      values
    });
  };

  const residenceStatus = watch('residence_status');
  const notUsCitizen = residenceStatus && residenceStatus !== 'U.S. Citizen';

  return (
    <>
      <h4 className="margin-xx">Residency Status</h4>
      <p className="size-16">Please confirm:</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="margin-xx">
          <Col sm={6}>
            <Controller
              control={control}
              name="residence_status"
              render={({ field }) => (
                <Select
                  {...setFieldProps(field, errors)}
                  label="Residency Status"
                  options={allResidencyStatuses.map(key => ({
                    label: key,
                    value: key
                  }))}
                />
              )}
            />
          </Col>
          {notUsCitizen && (
            <Col sm={6}>
              <Controller
                control={control}
                name="country_of_citizenship"
                render={({ field }) => (
                  <Select
                    {...setFieldProps(field, errors)}
                    label="Country of Citizenship"
                    options={countryOptions}
                  />
                )}
              />
            </Col>
          )}
        </Row>
        {residenceStatus === 'Other' && (
          <div className="error-message margin-x">
            Foreign nationals can only invest with us through accredited US
            entities (either the entity itself has $5m+ in investable assets or
            all the members/owners of the entity qualify as accredited.)
          </div>
        )}
        {notUsCitizen && (
          <Controller
            control={control}
            name="accepted_residency_requirements"
            render={({ field }) => (
              <Checkbox
                {...setCheckboxFieldProps(field, errors)}
                className="margin-x"
                label="I intend to invest through a U.S. based entity"
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="accepted_tos"
          render={({ field }) => (
            <Checkbox
              {...setCheckboxFieldProps(field, errors)}
              className="margin-xx"
              label={
                <div>
                  I agree to the latest{' '}
                  <a
                    href="https://www.equitymultiple.com/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.equitymultiple.com/privacy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              }
            />
          )}
        />

        <Button style={{ width: '240px' }} type="submit">
          Update
        </Button>
      </form>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect()(Residence);
