import {
  Alert,
  Button,
  Checkbox,
  ErrorLabel,
  Modal
} from '@equitymultiple/react-eui';
import { ButtonProps } from '@equitymultiple/react-eui/dist/components/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import FormError from 'components/FormError/FormError';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  loadInvestmentAccount,
  loadInvestmentAccountDocuments,
  makePrimaryBankAccount,
  removeBankAccount,
  updateInvestmentAccount
} from 'redux/actions/account';
import {
  AnyInvestmentAccountType,
  LoadInvestmentAccountAccount,
  LoadInvestmentAccountDocumentsDocuments
} from 'types/actions/account';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';
import { setCheckboxFieldProps } from 'utilities/formHelpers';
import humane from 'utilities/humane';
import utils from 'utilities/utils';
import { throwReactHookFormSubmissionErrors } from 'utilities/validation';

import * as sharedStyles from '../../AccountShared.module.scss';
import { cannotLinkNewBankAccountReason, isComplete } from '../../helpers';
import { bankTosSchema } from '../../validation';
import AccountWrap from '../AccountWrap/AccountWrap';
import BackLink from '../BackLink/BackLink';
import PlaidLink from '../PlaidLink/PlaidLink';
import * as styles from './BankAccounts.module.scss';

type Params = {
  reference_id: string;
};

interface Props {
  dispatch: Dispatch;
  documents: LoadInvestmentAccountDocumentsDocuments;
  investmentAccount: LoadInvestmentAccountAccount<AnyInvestmentAccountType>;
  loading: boolean;
}

interface FormFields {
  bank_account_tos: {
    opted_bank_linking_tos_and_pp: boolean;
  };
}

const BankAccounts = ({
  dispatch,
  documents,
  investmentAccount,
  loading
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const location = useLocation();

  const [internalError, setInternalError] = useState(null);
  const [message, setMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError
  } = useForm<FormFields>({
    resolver: yupResolver(bankTosSchema)
  });

  useEffect(() => {
    dispatch(loadInvestmentAccount(params.reference_id))
      .then(res => {
        if (res) {
          if (
            res.investment_account.opted_bank_linking_tos_and_pp &&
            (!res.investment_account.bank_accounts ||
              !res.investment_account.bank_accounts.length)
          ) {
            setMessage(
              'In order to proceed, you must have at least one linked bank account. Click "Link New Bank Account" to start.'
            );
          }
        }
      })
      .catch(err => handleErrorResponse(navigate, err));
    dispatch(loadInvestmentAccountDocuments(params.reference_id)).catch(err =>
      handleErrorResponse(navigate, err)
    );
  }, [dispatch, params, navigate]);

  const dispatchLoadInvestmentAccount = async () => {
    dispatch(loadInvestmentAccount(params.reference_id));
  };

  const setPrimaryBankAccount = id => {
    dispatch(makePrimaryBankAccount(id)).then(() => {
      dispatchLoadInvestmentAccount();
    });
  };

  const handleRemoveBankAccount = fundingSourceId => {
    dispatch(removeBankAccount(fundingSourceId))
      .then(() => {
        dispatchLoadInvestmentAccount();
        if (modalOpen) setModalOpen(false);
      })
      .catch(err => {
        setInternalError(err.body.message);
        if (modalOpen) setModalOpen(false);
      });
  };

  const onSubmit = values => {
    EmAnalytics.track('Opts in to Link Bank Account', 'Onboarding', {
      account_type: utils.startCase(investmentAccount.account_type)
    });
    return dispatch(
      updateInvestmentAccount({
        investment_account: {
          id: investmentAccount.id,
          opted_bank_linking_tos_and_pp:
            values.bank_account_tos.opted_bank_linking_tos_and_pp
        }
      })
    )
      .then(() => {
        dispatchLoadInvestmentAccount();
      })
      .catch(res => throwReactHookFormSubmissionErrors(res, setError));
  };

  const handlePlaidLinkSuccess = res => {
    if (res.linkingError) {
      setInternalError(res.linkingError);
    }
    dispatchLoadInvestmentAccount();
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const isOnWizard = () => {
    return location.pathname.includes('accounts/wizard');
  };

  const clearError = () => {
    setInternalError(null);
    setMessage(null);
  };

  const handlePlaidVerifyError = err => {
    setInternalError(err);
    dispatchLoadInvestmentAccount();
  };

  const renderBankAccount = bankAccount => {
    const unverified = bankAccount.status === 'unverified';
    const verifiableThroughPlaid = bankAccount.plaid && bankAccount.verifiable;

    const verifyButton = verifiableThroughPlaid ? (
      <PlaidLink
        bankAccountId={bankAccount.id}
        isUpdate
        LaunchButton={
          <button
            className="text-link"
            onClick={() => setInternalError(null)}
            type="button"
          >
            Verify Now
          </button>
        }
        onError={handlePlaidVerifyError}
        onSuccess={handlePlaidLinkSuccess}
      />
    ) : (
      <button className="text-link" onClick={toggleModal} type="button">
        Verify Now
      </button>
    );

    return (
      <div className={sharedStyles.account} key={bankAccount.id}>
        <Modal
          closeButtonText="Cancel"
          loading={isSubmitting}
          onClose={toggleModal}
          open={modalOpen}
          submitButton={
            <Button
              onClick={() =>
                handleRemoveBankAccount(bankAccount.funding_source_id)
              }
            >
              Remove Account
            </Button>
          }
          title={<h6>Account cannot be verified</h6>}
        >
          This account cannot be verified. Click the "Remove Account" button to
          remove the account, after which you can add it again.
        </Modal>
        <Row>
          <Col md={4}>
            <div className="text-label">Name</div>
            <p>{bankAccount.name}</p>
          </Col>
          <Col md={4}>
            <div className="text-label">Status</div>
            <p>
              <span
                className={
                  bankAccount.status === 'verified'
                    ? 'text-green'
                    : 'text-yellow'
                }
              >
                {bankAccount.status}
              </span>
              <span>{bankAccount.primary && ' (Primary)'} </span>
            </p>
          </Col>
          <Col md={4}>
            <div className="text-label">Account Type</div>
            <p className="text-capitalize">{bankAccount.type}</p>
          </Col>
        </Row>
        {bankAccount.iav || bankAccount.plaid ? (
          <Row>
            <Col md={4}>
              <div className="text-label">Bank Name</div>
              <p>{bankAccount.bank_name}</p>
            </Col>
            <Col md={4} offset={{ md: 4 }}>
              <div className="text-label" />

              {bankAccount.status === 'verified' && !bankAccount.primary && (
                <p>
                  <button
                    className={`text-link ${styles.makePrimary}`}
                    onClick={() => {
                      clearError();
                      setPrimaryBankAccount(bankAccount.funding_source_id);
                    }}
                    type="button"
                  >
                    Make Primary
                  </button>
                  <button
                    className={`text-link danger ${styles.removeAccount}`}
                    onClick={() => {
                      clearError();
                      handleRemoveBankAccount(bankAccount.funding_source_id);
                    }}
                    type="button"
                  >
                    Remove
                  </button>
                </p>
              )}

              {unverified && !isOnWizard() && verifyButton}
            </Col>
          </Row>
        ) : (
          <Row>
            {bankAccount.account_number && (
              <Col md={4}>
                <div className="text-label">Account Number</div>
                <p>{bankAccount.account_number}</p>
              </Col>
            )}
            {bankAccount.routing_number && (
              <Col md={4}>
                <div className="text-label">Routing Number</div>
                <p>{bankAccount.routing_number}</p>
              </Col>
            )}
            <Col md={4}>
              <div className="text-label" />

              {unverified && verifyButton}

              {bankAccount.status === 'verified' && !bankAccount.primary && (
                <p>
                  <button
                    className={`text-link ${styles.makePrimary}`}
                    onClick={() =>
                      setPrimaryBankAccount(bankAccount.funding_source_id)
                    }
                    type="button"
                  >
                    Make Primary
                  </button>
                  <button
                    className={`text-link danger ${styles.removeAccount}`}
                    onClick={() =>
                      handleRemoveBankAccount(bankAccount.funding_source_id)
                    }
                    type="button"
                  >
                    Remove
                  </button>
                </p>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const isLoading = loading || !investmentAccount || !documents;

  const acceptedTos = investmentAccount?.opted_bank_linking_tos_and_pp;
  const showContinueButton =
    investmentAccount?.bank_accounts?.length > 0 && isOnWizard();

  let showKycFailedReasons = false;
  let kycFailedReasons = [];
  if (!isLoading && !investmentAccount.can_link_bank_account) {
    if (
      investmentAccount.type === 'entity' &&
      (documents.entity_evidence_attachment?.dwolla_kyc_failed_reasons ||
        documents.ein_evidence_attachment?.dwolla_kyc_failed_reasons)
    ) {
      kycFailedReasons =
        documents.entity_evidence_attachment?.dwolla_kyc_failed_reasons ||
        documents.ein_evidence_attachment?.dwolla_kyc_failed_reasons;
    } else if (
      investmentAccount.type === 'joint account' &&
      documents.account_holder_2_id_document?.dwolla_kyc_failed_reasons
    ) {
      kycFailedReasons =
        documents.account_holder_2_id_document?.dwolla_kyc_failed_reasons;
    } else {
      kycFailedReasons = documents.id_document?.dwolla_kyc_failed_reasons;
    }

    showKycFailedReasons = kycFailedReasons?.length > 0;
  }

  const cannotLinkBankAccountReason =
    cannotLinkNewBankAccountReason(investmentAccount);

  const sharedBankButtonProps = {
    loading: isSubmitting,
    variant: 'orange' as ButtonProps['variant']
  };

  const PlaidLinkLaunchButton = (
    <Button onClick={clearError} {...sharedBankButtonProps}>
      Link New Bank Account
    </Button>
  );

  const labelTos = (
    <span>
      I agree to the{' '}
      <a
        className="text-link"
        href="https://www.dwolla.com/legal/tos/?whitelabel"
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms of Service{' '}
      </a>
      and{' '}
      <a
        className="text-link"
        href="https://www.dwolla.com/legal/privacy/?whitelabel"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy{' '}
      </a>
      of our partner Dwolla.
    </span>
  );

  let actionButton = (
    <PlaidLink
      LaunchButton={PlaidLinkLaunchButton}
      onError={err => setInternalError(err)}
      onSuccess={handlePlaidLinkSuccess}
    />
  );
  if (showContinueButton)
    actionButton = (
      <Button
        {...sharedBankButtonProps}
        wrapper={
          <Link
            to={`/accounts/wizard/${investmentAccount.account_type}/thanks/${investmentAccount.reference_id}`}
          />
        }
      >
        Continue
      </Button>
    );
  else if (cannotLinkBankAccountReason)
    actionButton = (
      <Button
        onClick={() => humane.error(cannotLinkBankAccountReason)}
        {...sharedBankButtonProps}
      >
        Link New Bank Account
      </Button>
    );

  return (
    <AccountWrap account={investmentAccount} loading={isLoading}>
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {isComplete(investmentAccount) ? (
            <h4 className="margin-top-0">Linked Bank Accounts</h4>
          ) : (
            <>
              <h4 className="margin-top-0">
                Lastly, securely link a bank account to your Investment Account
              </h4>
              <p className="margin-xx">
                To complete your Investment Account you must link a U.S.
                checking or savings bank account. This enables you to easily
                fund investments and receive distributions directly to your
                linked bank account.
              </p>
            </>
          )}

          {!acceptedTos ? (
            <div className="margin-xxx">
              <h6>Please review and agree to these terms and conditions</h6>
              <p className="margin-x">
                In order to use the online payment functionality of our
                platform, you must open a white label account provided by our
                payment processing partner Dwolla, Inc. (&ldquo;Dwolla&rdquo;)
                and you must accept the Dwolla Terms of Service and Privacy
                Policy. Any funds held in the Dwolla account are held by
                Dwolla&apos;s financial institution partners as set out in the
                Dwolla Terms of Service. You authorize us to share your identity
                and account data with Dwolla for the purposes of opening and
                supporting your Dwolla account, and you are responsible for the
                accuracy and completeness of that data. You understand that you
                will access and manage your Dwolla account through our
                application, and Dwolla account notifications will be sent by
                us, not Dwolla. We will provide customer support for your Dwolla
                account activity through the EquityMultiple platform.
              </p>
              <p className="margin-x">
                We also use Plaid to verify your bank account information, and
                may check the balance on your linked bank accounts to see if
                there’s enough money to cover funding an investment, and as
                otherwise permitted under our Privacy Policy.
              </p>
              <Controller
                control={control}
                name="bank_account_tos.opted_bank_linking_tos_and_pp"
                render={({ field }) => (
                  <Checkbox
                    {...setCheckboxFieldProps(field, errors)}
                    label={labelTos}
                  />
                )}
              />
            </div>
          ) : (
            <>
              {investmentAccount.bank_accounts?.length > 0 && (
                <div className="margin-x">
                  {investmentAccount.bank_accounts.map(bankAccount =>
                    renderBankAccount(bankAccount)
                  )}
                </div>
              )}
            </>
          )}

          {showKycFailedReasons ? (
            <div data-testid="kycFailedReasons">
              <ErrorLabel message="We have not yet been able to verify your investment account" />
              <div>
                <p>
                  Prior to linking a bank account we must verify it. Please note
                  that investment account verification review may take up to 48
                  hours or long. Your verification has failed for the following
                  reason(s):
                </p>
                <ul className={styles.kycFailedReasonsList}>
                  {kycFailedReasons.map((reason, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>{reason}</li>
                  ))}
                </ul>
                <p>
                  To receive assistance with this problem, please contact our
                  investor relations team at{' '}
                  <a href="mailto:help@equitymultiple.com">
                    help@equitymultiple.com
                  </a>
                </p>
              </div>
            </div>
          ) : (
            <>
              {internalError && (
                <ErrorLabel
                  message={internalError}
                  style={{ marginBottom: 20 }}
                />
              )}
              {message && <Alert>{message}</Alert>}
            </>
          )}

          <FormError errors={errors} />

          <div className="forwardBackButtonWrapCompact">
            {!acceptedTos ? (
              <Button type="submit" {...sharedBankButtonProps}>
                Accept Terms & Conditions
              </Button>
            ) : (
              actionButton
            )}
            <BackLink
              investmentAccount={investmentAccount}
              page="Bank Accounts"
            />
          </div>
        </form>
      )}
    </AccountWrap>
  );
};

function mapStateToProps(state) {
  return {
    documents: state.account.documents,
    investmentAccount: state.account.investmentAccount,
    loading: state.account.loading
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(BankAccounts);
